<template>
  <div class="card" v-if="show" :class="{ 'has-error': isError }">
    <div class="mb-8">
      <div class="title">{{ title }}</div>
      <div class="subtitle-1">
        Критерий как в приказе 203н: {{ titleFor203n }}
      </div>
    </div>
    <div>
      <SelectHealthcareType
        class="mb-8"
        :isStationar="answers.isStationar"
        :isAmbulatory="answers.isAmbulatory"
        :disabled="disabled"
        @change="onChange"
        @has-changes="onHasChanges"
        :comment="answers.typeComment"
      />
      <SelectFutureCriteries
        class="mb-8"
        :criteriaStatus="answers.criteriaStatus"
        :disabled="disabled"
        @change="onChange"
        @has-changes="onHasChanges"
        :comment="answers.statusComment"
      />
      <SelectNecessity
        class="mb-8"
        :criteriaNecessity="answers.criteriaNecessity"
        :disabled="disabled"
        @change="onChange"
        @has-changes="onHasChanges"
        :comment="answers.necessityComment"
      />
    </div>
    <GvsCardStatus
      class="mb-2"
      :isError="isError"
      :isLoading="isLoading"
      :hasChanges="hasChanges"
      :isReady="isReady"
      :isInitial="isInitial"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import SelectHealthcareType from '@/components/gvs/SelectHealthcareType.vue'
import SelectFutureCriteries from '@/components/gvs/SelectFutureCriteries.vue'
import SelectNecessity from '@/components/gvs/SelectNecessity.vue'
import GvsCardStatus from '@/components/gvs/GvsCardStatus.vue'

import { updateCriteriaCard } from '@/lib/requestsAPI'
import { isGVSAnswersReady } from '@/lib/answers'

import { typograph } from '@/lib/typograph.js'

export default {
  props: ['userCriteriaStage', 'disabled'],
  data() {
    return {
      isLoading: false,
      isError: false,
      hasChanges: false,
      answers: {},
      TEMPLATE_USER_PR_NOS: {
        isStationar: false,
        isAmbulatory: false,
        criteriaStatus: 0,
        criteriaNecessity: 0,
        typeComment: '',
        statusComment: '',
        necessityComment: '',
      },
    }
  },
  components: {
    SelectHealthcareType,
    SelectFutureCriteries,
    SelectNecessity,
    GvsCardStatus,
  },
  created() {
    this.answers = this.userCriteriaStageAnswers
      ? this.userCriteriaStageAnswers
      : this.TEMPLATE_USER_PR_NOS
  },
  watch: {
    isReady: {
      immediate: true,
      handler() {
        this.$emit('ready', {
          index: this.userCriteriaStage.id,
          isReady: this.isReady,
        })
      },
    },
  },
  computed: {
    criterion() {
      return this.userCriteriaStage.criterionStage.criterion || {}
    },
    show() {
      return this.userCriteriaStage.show
    },
    title() {
      const { numInOrder } = this.userCriteriaStage
      return typograph(numInOrder + '. ' + this.criterion.name)
    },
    titleFor203n() {
      return this.criterion.name_203n
        ? typograph(this.criterion.name_203n)
        : 'Критерий отсутствует в действующем приказе 203н'
    },
    userCriteriaStageAnswers() {
      return this.userCriteriaStage.data
    },
    isReady() {
      return isGVSAnswersReady(this.answers)
    },
    isInitial() {
      // Если все ответы falsy вернуть true
      return Object.values(this.answers).every(a => Boolean(a) === false)
    },
    sendingParams() {
      const { users_projects_nosology_id, criteria_stage_id } =
        this.userCriteriaStage
      return { users_projects_nosology_id, criteria_stage_id }
    },
  },
  methods: {
    onHasChanges(evt) {
      this.hasChanges = evt
    },
    onChange({ name, value }) {
      if (this.isError) {
        this.$toast.warning(
          'Ошибка сохранения карточки. Перезагрузите страницу'
        )
        return
      }

      if (this.isLoading) {
        this.$toast.warning('Подождите. Сохраняю прошлые изменения')
        return
      }
      this.isLoading = true
      updateCriteriaCard({
        ...this.sendingParams,
        data: { ...this.answers, [name]: value },
      })
        .then(res => {
          this.answers = res
          this.isLoading = false
          this.hasChanges = false
        })
        .catch(err => {
          this.isError = true
          console.error(err)
          this.$toast.error(
            `Ошибка сохранения карточки ${this.userCriteriaStage.id}`
          )
        })
    },
  },
}
</script>

<style scoped>
.card {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.has-error {
  border-color: red;
  background-color: rgba(255, 0, 0, 0.1);
}
</style>
