<template>
  <div>
    <div class="mb-2">
      Оцените, пожалуйста, при экспертизе качества каких видов медицинской
      помощи может применяться этот критерий:
    </div>
    <div class="mb-2">
      <v-checkbox
        v-for="ch in checkboxes"
        :key="ch.id"
        :label="ch.label"
        :input-value="ch.value"
        :disabled="disabled"
        @click="ch.clickHandler"
        readonly
        hide-details
        class="ma-0 mt-1 pa-0"
      ></v-checkbox>
    </div>
    <CommentBlock
      :comment="comment"
      :disabled="disabled"
      @save="onCommentChange"
      @has-changes="throwEvent"
    />
  </div>
</template>

<script>
import CommentBlock from '@/components/gvs/CommentBlock.vue'

export default {
  components: { CommentBlock },
  props: ['isStationar', 'isAmbulatory', 'comment', 'disabled'],
  computed: {
    checkboxes() {
      return [
        {
          id: 1,
          label:
            'Специализированная, в том числе ВМП (стационар и дневной стационар)',
          value: this.isStationar,
          clickHandler: this.onStationarChange,
        },
        {
          id: 2,
          label:
            'Первичная медико-санитарная (амбулаторные условия и дневной стационар)',
          value: this.isAmbulatory,
          clickHandler: this.onAmbulatorySChange,
        },
      ]
    },
  },
  methods: {
    onStationarChange() {
      if (this.disabled) return
      this.$emit('change', { name: 'isStationar', value: !this.isStationar })
    },
    onAmbulatorySChange() {
      if (this.disabled) return
      this.$emit('change', { name: 'isAmbulatory', value: !this.isAmbulatory })
    },
    onCommentChange(evt) {
      if (this.disabled) return
      this.$emit('change', { name: 'typeComment', value: evt })
    },
    throwEvent(evt) {
      this.$emit('has-changes', evt)
    },
  },
}
</script>
