<template>
  <div>
    <span>Оцените, пожалуйста, по 9-ти бальной шкале&nbsp;</span>
    <v-tooltip bottom :max-width="600" color="white">
      <template v-slot:activator="{ on, attrs }">
        <span
          ><span v-bind="attrs" v-on="on" class="tooltip-activator-text"
            >необходимость</span
          ><sup class="tooltip-activator-sup">?</sup></span
        ></template
      >
      <div class="tooltip-content">{{ tooltipText }}</div>
    </v-tooltip>
    <span>&nbsp;критерия, где: 1 — Не нужен, 9 — Абсолютно необходим.</span>
  </div>
</template>

<script>
export default {
  computed: {
    tooltipText() {
      return (
        'Необходимость — мера обязательности рекомендации пациенту проведения именно этого вмешательства. ' +
        'Пациент может отказаться от\xa0вмешательства. ' +
        'Абсолютную необходимость можно выразить следующим образом: ' +
        '«Это будет грубым нарушением, если врач не\xa0предложит пациенту данное вмешательство, ' +
        'и\xa0может иметь тяжелые последствия для врача, например, увольнение, судебное разбирательство».'
      )
    },
  },
}
</script>

<style scoped>
.tooltip-activator-text {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.4);
}
.tooltip-activator-sup {
  font-weight: bold;
}
.tooltip-content {
  border: 1px solid grey;
  background-color: white;
  opacity: 1;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  padding: 8px;
}
</style>
