<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <PageHeader :text="nosologia.name">
        <ExcelButton :loadUrl="loadUrl" :nosologia="nosologia" />
      </PageHeader>
      <div class="mb-4">
        <TitleAndDataLine label="МКБ10" :value="nosologia.mkb10_codes" />
        <TitleAndDataLink label="Ссылка" :value="nosologia.cr_link" />
      </div>

      <div class="mb-6">
        <div v-if="isSend">Вы уже отправили ответы.</div>
        <div v-else>
          <SaveStatus class="mb-4" />
          <ReadyStatus
            class="mb-4"
            :ready="ready"
            :total="total"
            :isDone="isDone"
            :hideReady="hideReady"
            :hasChanges="hasChanges"
          />
          <AnswersButtons
            :isDone="isDone"
            :hideReady="hideReady"
            :hasChanges="hasChanges"
            @hide-ready="onHideReady"
            @show-all="onShowAll"
          />
        </div>
      </div>

      <div class="mb-8">
        <GvsCriteriaCard
          v-for="userCriteriaStage in preparedCriteries"
          :key="userCriteriaStage.id"
          :userCriteriaStage="userCriteriaStage"
          :disabled="isSend"
          class="mb-3"
          @ready="onCardReady"
        />
      </div>

      <div v-if="isDone && !isSend">
        Вы оценили все критерии для этого заболевания (состояния). Теперь
        отправьте ответы.
      </div>
      <SendAnswersButton v-if="!isSend" :isDone="isDone" />
    </div>
  </DisplayLoading>
</template>

<script>
import GvsCriteriaCard from '@/components/gvs/GvsCriteriaCard.vue'
import ExcelButton from '@/components/buttons/ExcelButton.vue'
import SaveStatus from '@/components/gvs/SaveStatus.vue'
import ReadyStatus from '@/components/gvs/ReadyStatus.vue'
import AnswersButtons from '@/components/gvs/AnswersButtons.vue'
import SendAnswersButton from '@/components/gvs/SendAnswersButton.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DisplayLoading from '@/components/common/DisplayLoading.vue'
import TitleAndDataLine from '@/components/common/TitleAndDataLine.vue'
import TitleAndDataLink from '@/components/common/TitleAndDataLink.vue'

import { routeParams } from '@/mixins/routeParams'
import { loadPageData } from '@/mixins/loadPageData'

import { API_USERS_PROJECT_NOSOLOGIES } from '@/store/const/apiPaths'

export default {
  data() {
    return {
      hideReady: false,
      hasChanges: false,
      loadingMessage:
        'Загружаю список вопросов по выбранному заболеванию (состоянию)',
      answersState: {},
      savedAnswersState: {},
    }
  },
  mixins: [routeParams, loadPageData],
  components: {
    GvsCriteriaCard,
    PageHeader,
    DisplayLoading,
    TitleAndDataLine,
    TitleAndDataLink,
    SaveStatus,
    ReadyStatus,
    AnswersButtons,
    SendAnswersButton,
    ExcelButton,
  },
  computed: {
    loadUrl() {
      return `${API_USERS_PROJECT_NOSOLOGIES}/${this.userProjectNosologiaId}`
    },
    errorMessage() {
      return `Не удалось загрузить данные опросника c id ${this.userProjectNosologiaId}`
    },
    userProjectNosologia() {
      return this.pageData
    },
    isSend() {
      return this.userProjectNosologia.is_completed
    },
    answers() {
      return Object.values(this.answersState)
    },
    notAllAnswers() {
      return this.answers.some(v => v === false)
    },
    ready() {
      return this.answers.filter(v => v).length
    },
    total() {
      // подумать как не пересчитывать total при изменения внутри answers (в процессе работы длинна не меняется)
      return this.answers.length
    },
    isDone() {
      return this.ready === this.total
    },
    nosologia() {
      return this.userProjectNosologia.projectNosology.nosology
    },
    preparedCriteries() {
      return this.userProjectNosologia.userCriterionStages.map(
        (ucs, index) => ({
          ...ucs,
          numInOrder: index + 1,
          show: this.isShow(ucs.id),
        })
      )
    },
  },
  methods: {
    isShow(id) {
      return this.hideReady
        ? this.savedAnswersState[id] === false ||
            this.savedAnswersState[id] === undefined
        : true
    },
    onCardReady({ index, isReady }) {
      this.answersState = { ...this.answersState, [index]: isReady }
      this.hasChanges = true
    },
    updateReady() {
      this.savedAnswersState = { ...this.answersState }
      this.hasChanges = false
    },
    onHideReady() {
      this.hideReady = true
      this.updateReady()
    },
    onShowAll() {
      this.hideReady = false
    },
  },
}
</script>
