<template>
  <div>
    <div class="mb-2">
      Отметьте, пожалуйста, критерии, которые могут применяться в будущем при
      условии появления связи «амбулатория-стационар» как оценка на определенном
      этапе оказания медицинской помощи или как общий КК (например, 1 раз в год)
    </div>

    <v-radio-group
      :value="criteriaStatus"
      :disabled="disabled"
      readonly
      hide-details
      class="mt-0 mb-2 pa-0"
      row
    >
      <v-radio
        v-for="r in radio"
        :key="r.value"
        :value="r.value"
        :label="r.label"
        @click="r.clickHandler"
        class="ma-0 mt-1 mr-8 pa-0"
      ></v-radio>
    </v-radio-group>

    <CommentBlock
      :comment="comment"
      :disabled="disabled"
      @save="onCommentChange"
      @has-changes="throwEvent"
    />
  </div>
</template>

<script>
import CommentBlock from '@/components/gvs/CommentBlock.vue'
import { CRITERIA_STATUS } from '@/store/const/criteriaStatus'

export default {
  props: ['criteriaStatus', 'comment', 'disabled'],
  components: { CommentBlock },
  computed: {
    radio() {
      return Object.keys(CRITERIA_STATUS)
        .map(key => Number(key))
        .sort()
        .map(key => ({
          label: CRITERIA_STATUS[key].name,
          value: key,
          clickHandler: () => this.onRadioChange(key),
        }))
    },
  },
  methods: {
    onRadioChange(n) {
      this.$emit('change', { name: 'criteriaStatus', value: n })
    },
    onCommentChange(evt) {
      this.$emit('change', { name: 'statusComment', value: evt })
    },
    throwEvent(evt) {
      this.$emit('has-changes', evt)
    },
  },
}
</script>
